<template>
    <v-row class="my-0 py-12">
        <v-col v-if="isEditorial && isBroken" class="my-12 py-12 text-center">
            Cannot generate preview of this video (ID: {{ videoId }})
            <br />
            Please contact support
        </v-col>
        <v-col v-else class="my-12 py-12 text-center">
            <v-progress-circular indeterminate color="primary" class="mr-2" />
            Generating video preview...
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { mapGetters } from 'vuex';

import { Endpoint } from '@/mixins';
import { VideoStatus } from '@/types/Video';

type PreviewGenerate = { preview_src: string; status: VideoStatus };

const VideoPreviewGeneratorProps = Vue.extend({
    name: 'VideoPreviewGenerator',
    props: {
        videoId: {
            type: [String, Number],
            default() {
                return 0;
            }
        }
    }
});

@Component({
    computed: {
        ...mapGetters('user', ['isAuthor', 'isEditor', 'isAdmin'])
    }
})
export default class VideoPreviewGenerator extends mixins(
    VideoPreviewGeneratorProps,
    Endpoint
) {
    isAuthor!: boolean;
    isEditor!: boolean;
    isAdmin!: boolean;

    endpoint = '/video_prs/generate_preview';

    isMounted = false;

    isBroken = false;

    interval = 5000;

    get isEditorial() {
        return this.isAdmin || this.isAuthor || this.isEditor;
    }

    get sourceUrl() {
        return [this.endpoint, this.videoId].join('/');
    }

    onMounted() {
        this.isMounted = true;

        if (this.videoId) {
            this.load();
        }
    }

    beforeDestroy() {
        this.abort();

        this.isMounted = false;
    }

    onData(data: PreviewGenerate) {
        if (data?.preview_src) {
            return this.$emit('generated');
        }

        if (data?.status === VideoStatus.Draft) {
            // cannot preview, return to edit mode
            return this.$emit('error');
        }

        if (data?.status === VideoStatus.Error) {
            // stop waiting for preview
            this.isBroken = true;
        } else {
            setTimeout(() => {
                if (this.isMounted) {
                    this.load();
                }
            }, this.interval);
        }
    }
}
</script>
